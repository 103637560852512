import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import { findMedia } from '@interness/web-core/src/components/_helpers';

import { H3 } from '../components/styles';

function ZellwollbaenderPage(props) {
  const pdf = findMedia(props.data.pdf.media, '4-zellwollband').publicURL;
  const muster = findMedia(props.data.images.media, 'zellwolle').childImageSharp.fixed;
  return (
    <>
      <HeaderMedia id={'zellwollbaender'}/>
      <Wrapper>
        <Spacer/>
        <Heading>Zellwollband</Heading>
        <Spacer height={20}/>
        <p>
          Unsere Zellwollbänder mit Kennfaden werden im Elektrobereich eingesetzt und eignen sich hervorragend zum
          Abbinden und Isolieren.
          Weitgehend unempfindlich gegen Oxydationseinflüsse, Säure und Bakterien
          besitzen eine hohe, langanhaltende Temperaturbeständigkeit und hohe Reißfestigkeit.
        </p>
        <Separator/>
        <H3>Muster</H3>
        <div style={{ textAlign: 'center' }}>
          <Img fixed={muster} alt='zellwollwand'/>
        </div>
        <Separator/>
        <H3>Spezifikation</H3>
        <table>
          <tbody>
          <tr>
            <td><b>Bezeichnung</b></td>
            <td>Jaconettband</td>
          </tr>
          <tr>
            <td><b>Material</b></td>
            <td>Zellwolle</td>
          </tr>
          <tr>
            <td><b>Gewebebindung</b></td>
            <td>LW - Bindung</td>
          </tr>
          <tr>
            <td><b>Gewebestellung</b></td>
            <td>Kette : 27 Fd Tex 50 per cm,
              Schuß : 8x2 Fc Tex 12 per cm
            </td>
          </tr>
          <tr>
            <td><b>Gewicht</b></td>
            <td>150 g / 100m / per cm</td>
          </tr>
          <tr>
            <td><b>Reißfestigkeit</b></td>
            <td>200 N per cm</td>
          </tr>
          <tr>
            <td><b>Reißdehnung</b></td>
            <td>ca. 6%</td>
          </tr>
          </tbody>
        </table>
        <a href={pdf} target='_blank'>Download Datenblatt (PDF)</a>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default ZellwollbaenderPage;

export const query = graphql`
    query {
        pdf: directusMediaCollection(name: {eq: "pdf"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                    }
                }
            }
        }
        images: directusMediaCollection(name: {eq: "muster"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;